import React from "react";
import { BsPencilFill } from "react-icons/bs";
import "./Sidenav.css";

const Sidebar = ({
  descriptions,
  selectedDescription,
  setSelectedDescription,
  handleStartNewCampaign,
}) => {
  return (
    <div className="sidebar">
      <h2>My Campaigns</h2>
      <ul className="menu-list">
        {/* Option to start a new campaign */}
        <li
          className={`menu-item-wrap-new ${
            !selectedDescription ? "active" : ""
          }`}
          onClick={handleStartNewCampaign}
        >
          <a>Start New Campaign</a>
        </li>

        {/* Dynamically render campaigns */}
        {descriptions.map((desc) => (
          <li
            key={desc.id}
            className={`menu-item-wrap ${
              selectedDescription?.id === desc.id ? "active" : ""
            }`}
            onClick={() => setSelectedDescription(desc)}
          >
            <a>{desc.id}</a>
            <div className="icon-wrap">
              <BsPencilFill size={18} color="#28a745" className="item-icon" />
            </div>
          </li>
        ))}
      </ul>
      <div className="footer-place"></div>
    </div>
  );
};

export default Sidebar;
